import React, { useState, useEffect } from 'react'
import { node, shape, string } from 'prop-types'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import {
  Switch,
  Route,
  Redirect,
  withRouter,
} from 'react-router'
import { InMemoryCache } from 'apollo-boost'
import firebase from 'firebase/app'
import 'firebase/auth'
import AuthContext from 'context/AuthContext'
import { concat } from 'apollo-link'
import Loadable from 'react-loadable'
import Loading from 'components/Loading'

const LoadableLogin = Loadable({
  loader: () => import('views/Login'),
  loading: Loading,
})

const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY_PS_MAIN
const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PS_MAIN
const FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL_PS_MAIN
const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID_PS_MAIN
const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PS_MAIN
const FIREBASE_MESSAGE_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID_PS_MAIN

firebase.initializeApp({
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messageSenderId: FIREBASE_MESSAGE_SENDER_ID,
});

const firebaseAuth = firebase.auth()

const link = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT })

const authLink = setContext(async (operation, { headers }) => {
  const token = await firebaseAuth.currentUser.getIdToken(true)
  return {
    headers: {
      ...headers,
      authorization: token || null,
    },
  }
})

const Authorization = (props) => {
  const { children, history, location: { key } } = props
  const [initialized, setInitialized] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [authStatus, setAuthStatus] = useState('IDLE')
  const [resetStatus, setResetStatus] = useState('IDLE')
  const [signUpStatus, setSignUpStatus] = useState('IDLE')
  const [error, setError] = useState(null)

  useEffect(() => firebaseAuth.onAuthStateChanged(async (user) => {
    setError(null)
    setAuthStatus('PENDING')
    if (user) {
      const token = await user.getIdToken(true)
      if (token) {
        setLoggedIn(true)
      } else {
        setLoggedIn(false)
      }

      setAuthStatus('FULFILLED')
      setInitialized(true)
    } else {
      setLoggedIn(false)
      setAuthStatus('IDLE')
      setInitialized(true)
    }
  }), [])

  useEffect(() => async () => {
    try {
      setResetStatus('IDLE')
      setError(null)
      setInitialized(true)
    } catch (e) {
      setLoggedIn(false)
      setAuthStatus('IDLE')
      setInitialized(true)
    }
  }, [key])

  if (!initialized) return <Loading />

  const signInUser = async (email = '', password = '') => {
    try {
      setAuthStatus('PENDING')
      setError(null)
      await firebaseAuth.signInWithEmailAndPassword(email, password).catch((e) => {
        setError(e)
        setAuthStatus('REJECTED')
      })
      return true
    } catch (e) {
      return e
    }
  }

  const resetPassword = async (email) => {
    try {
      setError(null)
      setResetStatus('PENDING')
      return await firebase.auth().sendPasswordResetEmail(email)
        .then(() => {
          setResetStatus('FULFILLED')
        })
        .catch((e) => {
          setError(e)
          setResetStatus('REJECTED')
        })
    } catch (e) {
      return e
    }
  }

  const signOutUser = async () => {
    try {
      await firebaseAuth.signOut()
      setLoggedIn(false)
      setAuthStatus('IDLE')
      return true
    } catch (e) {
      return e
    }
  }

  const signUpUser = async (email = '', password = '') => {
    try {
      setSignUpStatus('PENDING')
      setError(null)
      await firebaseAuth.createUserWithEmailAndPassword(email, password)
        .then(() => {
          setSignUpStatus('FULFILLED')
          history.push('/')
        })
        .catch((e) => {
          setError(e)
          setSignUpStatus('REJECTED')
        })
      return true
    } catch (e) {
      return e
    }
  }

  const authContext = {
    actions: {
      resetPassword,
      signInUser,
      signOutUser,
      signUpUser,
    },
    error,
    status: {
      auth: authStatus,
      reset: resetStatus,
      signUp: signUpStatus,
    },
  }

  if (!loggedIn) {
    return (
      <AuthContext.Provider value={authContext}>
        <Switch>
          <Route
            path="/"
            component={LoadableLogin}
          />
          <Redirect from="*" to="/" />
        </Switch>
      </AuthContext.Provider>
    )
  }

  const client = new ApolloClient({
    link: concat(authLink, link),
    cache: new InMemoryCache(),
  })

  return (
    <AuthContext.Provider value={authContext}>
      <ApolloProvider client={client}>
        {children}
      </ApolloProvider>
    </AuthContext.Provider>
  )
}

Authorization.propTypes = {
  children: node.isRequired,
  location: shape({
    key: string.isRequired,
  }).isRequired,
  history: shape().isRequired,
}

export default withRouter(Authorization)
