import React from 'react'
import { shape } from 'prop-types'
import { NavLink, withRouter } from 'react-router-dom'
import { containers } from 'config'
import Text from 'components/Text'
import MachinesTab from 'components/icons/MachinesTab'
import RecipesTab from 'components/icons/RecipesTab'
// import Graph from 'components/icons/Graph'
import Gear from 'components/icons/Gear'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { styles as s, colors } from 'stylesheet'
import { withApp } from 'context/AppContext'

const machinesPath = (lastViewedMachine) => {
  let { path } = containers.MachineIndex
  if (lastViewedMachine) path += `/${lastViewedMachine}`
  return path
}

const recipesPath = (lastViewedRecipe) => {
  let { path } = containers.RecipeIndex
  if (lastViewedRecipe) path += `/${lastViewedRecipe}`
  return path
}

const machinesLinkActive = (match, location) =>
  location.pathname.includes(containers.MachineIndex.path)
  && !location.pathname.includes('settings')

const recipesLinkActive = (match, location) =>
  location.pathname.includes(containers.RecipeIndex.path)

// const statsLinkActive = (match, location) =>
//   location.pathname.includes(containers.StatsIndex.path)

const settingsLinkActive = (match, location) =>
  location.pathname.includes(containers.SettingsIndex.path)

const StyledFooter = styled.footer({

  paddingBottom: 'env(safe-area-inset-bottom)',

  '.nav-items': css(s.flex, s.alignCenter, s.justifyCenter, {
    height: '50px',
    borderTop: `1px solid ${colors.lightGrey}`,
  }),

  a: css(s.grey),

  '.active': css(s.black),

  '.tab': css(s.flex, s.alignCenter, s.justifyCenter, s.flexColumn, {
    height: '100%',
    width: '94px',
    fontSize: '10px',
    textDecoration: 'none',

    svg: {
      marginBottom: '3px',
    },
  }),
})

const Footer = (props) => {
  const { app, location } = props
  const {
    lastViewed: { machine, recipe },
  } = app

  return (
    <StyledFooter>
      <div className="nav-items">
        <NavLink
          isActive={machinesLinkActive}
          className="tab"
          to={machinesPath(machine)}
        >
          <MachinesTab
            color={machinesLinkActive('', location) ? colors.black : colors.grey}
          />
          <Text variant="h2">{containers.MachineIndex.title}</Text>
        </NavLink>
        <NavLink
          isActive={recipesLinkActive}
          className="tab"
          to={recipesPath(recipe)}
        >
          <RecipesTab
            color={recipesLinkActive('', location) ? colors.black : colors.grey}
          />
          <Text variant="h2">{containers.RecipeIndex.title}</Text>
        </NavLink>
        {/* TODO - reenable when stats are ready to go live */}
        {/* <NavLink
          isActive={statsLinkActive}
          className={localClasses.tab}
          to={containers.StatsIndex.path}
        >
          <Graph
            color={statsLinkActive('', location) ? colors.black : colors.grey}
          />
          <Text variant="h2">{containers.StatsIndex.title}</Text>
        </NavLink> */}
        <NavLink
          isActive={settingsLinkActive}
          className="tab"
          to={containers.SettingsIndex.path}
        >
          <Gear
            color={settingsLinkActive('', location) ? colors.black : colors.grey}
          />
          <Text variant="h2">{containers.SettingsIndex.title}</Text>
        </NavLink>
      </div>
    </StyledFooter>
  )
}

Footer.propTypes = {
  app: shape({
    lastViewed: shape().isRequired,
  }).isRequired,
  location: shape().isRequired,
}

export default withRouter(withApp(Footer))
