/* eslint no-underscore-dangle: 0, no-undef: 0 */
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import Authorization from 'Auth'

import './index.css';
import './components/animations/AnimateFromTop.css';
import 'react-flexbox-grid/dist/react-flexbox-grid.css'
import App from './App';

ReactDOM.render(
  <BrowserRouter>
    <Authorization>
      <App />
    </Authorization>
  </BrowserRouter>,
  document.getElementById('app-root'),
);
