import React, { createContext } from 'react'

const AppContext = createContext()

export const withApp = Component => props => (
  <AppContext.Consumer>
    {app => <Component {...props} app={app} />}
  </AppContext.Consumer>
)

export default AppContext
