import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'stylesheet';

const RecipesTab = ({ color }) => (
  <svg
    width="30px"
    height="23px"
    viewBox="0 0 30 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(0.000000, -2.000000)" stroke={color}>
        <g transform="translate(0.500000, 2.000000)">
          <path d="M3.49705882,17.1441176 L9.12647059,17.1441176 L3.49705882,17.1441176 Z M3.49705882,14.1588235 L7.42058824,14.1588235 L3.49705882,14.1588235 Z M3.49705882,11.0882353 L9.12647059,11.0882353 L3.49705882,11.0882353 Z M3.49705882,8.01764706 L7.42058824,8.01764706 L3.49705882,8.01764706 Z M3.49705882,5.03235294 L9.12647059,5.03235294 L3.49705882,5.03235294 Z M23.7970588,0.511764706 L23.7970588,17.1441176 C23.7970588,20.0441176 21.4088235,22.4323529 18.5088235,22.4323529 L5.71470588,22.4323529 C2.81470588,22.4323529 0.426470588,20.0441176 0.426470588,17.1441176 L0.426470588,0.511764706 L23.7970588,0.511764706 Z M24,4.09411765 C26.5588235,4.09411765 28.5205882,6.14117647 28.5205882,8.61470588 C28.5205882,11.0882353 26.4735294,13.1352941 24,13.1352941 L24,4.09411765 Z" />
        </g>
      </g>
    </g>
  </svg>
);

RecipesTab.defaultProps = {
  color: colors.black,
};

RecipesTab.propTypes = {
  color: PropTypes.string,
};

export default RecipesTab;
