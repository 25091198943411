import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import Footer from 'components/Footer'
import { containers } from 'config'
import AppContext from 'context/AppContext'
import ActionsContext from 'context/ActionsContext'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { styles as s } from 'stylesheet'
import Loadable from 'react-loadable'
import Loading from 'components/Loading'

const LoadableMachines = Loadable({
  loader: () => import('views/Machines'),
  loading: Loading,
})
const LoadableRecipes = Loadable({
  loader: () => import('views/Recipes'),
  loading: Loading,
})
const LoadableSettings = Loadable({
  loader: () => import('views/Settings'),
  loading: Loading,
})

const StyledApp = styled.div(s.flex, s.flexColumn, {
  minHeight: '100vh',

  '.content': css(s.grow1, s.bgLightGrey, s.flex),

  '.footer': css(s.bgWhite, {
    position: 'sticky',
    bottom: '0',
    zIndex: 9999999,
  }),
})

const {
  Login,
  MachineIndex,
  RecipeIndex,
  SettingsIndex,
} = containers

const App = () => {
  const [lastViewedRecipe, setLastViewedRecipe] = useState(null)
  const [lastViewedMachine, setLastViewedMachine] = useState(null)

  return (
    <AppContext.Provider
      value={{
        lastViewed: {
          machine: lastViewedMachine,
          recipe: lastViewedRecipe,
        },
      }}
    >
      <ActionsContext.Provider
        value={{
          setLastViewedRecipe,
          setLastViewedMachine,
        }}
      >
        <StyledApp>
          <div className="content">
            <Switch>
              <Redirect
                key={Login.slug}
                exact
                from={Login.path}
                to={MachineIndex.path}
              />
              <Route
                key={MachineIndex.slug}
                path={MachineIndex.path}
                component={LoadableMachines}
              />
              <Route
                key={RecipeIndex.slug}
                path={RecipeIndex.path}
                component={LoadableRecipes}
              />
              <Route
                key={SettingsIndex.slug}
                path={SettingsIndex.path}
                component={LoadableSettings}
              />
            </Switch>
          </div>
          <div className="footer">
            <Footer />
          </div>
        </StyledApp>
      </ActionsContext.Provider>
    </AppContext.Provider>
  )
}

export default App
