import parameterize from 'parameterize';

const createContainerConfig = (
  title,
  label = null,
  slug = null,
  path = null,
) => {
  const generatedLabel = label || title;
  const generatedSlug = slug || parameterize(generatedLabel);
  const generatedPath = path || `/${generatedSlug}`;

  return {
    label: generatedLabel,
    path: generatedPath,
    slug: generatedSlug,
    title,
  };
};

export default {
  Login: createContainerConfig('Login', 'Login', 'login', '/'),
  MachineIndex: createContainerConfig('Machines'),
  MachineShow: createContainerConfig(
    'Machine',
    'Machine',
    'machines',
    '/machines/:machineID',
  ),
  MachineSettings: createContainerConfig(
    'Machine Settings',
    'Machine Settings',
    'machine-settings',
    '/machines/:machineID/settings',
  ),
  MachineCreate: createContainerConfig(
    'Machine Create',
    'Machine Create',
    'machine-create',
    '/machines/new',
  ),
  MachineConnectedCreate: createContainerConfig(
    'Machine Connected Create',
    'Machine Connected Create',
    'machine-connected-create',
    '/machines/new/connected',
  ),
  RecipeIndex: createContainerConfig('Recipes'),
  RecipeEdit: createContainerConfig(
    'Recipe Edit',
    'Recipe Edit',
    'recipe-edit',
    '/recipes/:recipeID',
  ),
  RecipeCreate: createContainerConfig(
    'Create Recipe',
    'Create Recipe',
    'recipe-create',
    '/recipes/new',
  ),
  SettingsIndex: createContainerConfig('Settings'),
  SettingsMachines: createContainerConfig(
    'Settings Machines',
    'Settings Machines',
    'settings-machines',
    '/settings/machines',
  ),
  SettingsUser: createContainerConfig(
    'User Settings',
    'User Settings',
    'user-settings',
    '/settings/user',
  ),
  SettingsUsers: createContainerConfig(
    'Settings Users',
    'Settings Users',
    'settings-users',
    '/settings/users',
  ),
  StationEdit: createContainerConfig(
    'Station Edit',
    'Station Edit',
    'station-edit',
    '/machines/:machineID/:stationID',
  ),
  StatsIndex: createContainerConfig('Statistics'),
  StatsShow: createContainerConfig(
    'Machine Statistics Show',
    'Machine Statistics Show',
    'machine-statistics-show',
    '/statistics/:machineID',
  ),
};
