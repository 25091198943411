import React from 'react';
import { string } from 'prop-types'
import Spinner from 'react-spinkit'
import { styles as s, colors } from 'stylesheet';
import styled from '@emotion/styled'

const StyledContainer = styled.div(s.flex, s.grow1, s.alignCenter, s.justifyCenter, {
  width: '100%',
})

const Loading = ({ color }) => (
  <StyledContainer>
    <Spinner name="double-bounce" color={color} fadeIn="none" />
  </StyledContainer>
);

Loading.defaultProps = {
  color: colors.black,
}

Loading.propTypes = {
  color: string,
}

export default Loading;
