import React, { createContext } from 'react'

const ActionsContext = createContext()

export const withActions = Component => props => (
  <ActionsContext.Consumer>
    {actions => <Component {...props} actions={actions} />}
  </ActionsContext.Consumer>
)

export default ActionsContext
