import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'stylesheet';

const MachinesTab = ({ color }) => (
  <svg
    width="50px"
    height="23px"
    viewBox="0 0 50 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-21.000000, -7.000000)" fill={color}>
        <g transform="translate(21.000000, 6.000000)">
          <g transform="translate(0.000000, 1.000000)">
            <path
              d="M49.2033103,1.09372347 L45.5985995,1.0935433 L45.5985995,2.80414457 L4.65456338,2.80414457 L4.65456338,2.45143267 L4.65528606,1.05830407 L1.0934069,1.05708112 L1.0934069,22.2208498 L49.2033103,22.2208498 L49.2033103,1.09372347 Z M4.90288766,1.05838908 L4.88148423,1.05838173 C4.88899103,1.05895174 4.8961636,1.05895958 4.90288766,1.05838908 Z M49.2991947,1.09372826 L49.2907887,1.09372784 C49.2938069,1.0941305 49.2966296,1.09413924 49.2991947,1.09372826 Z M44.8697066,2.09872077 L44.8698553,0.934486497 C44.8798621,0.637554232 45.1255829,0.398305868 45.4446428,0.38830446 L49.2631144,0.39003697 C49.5892853,0.358635345 49.8822716,0.582224474 49.9285884,0.896124605 L49.9322482,22.9262736 L0.364468965,22.9262736 L0.364468965,22.5735617 L0.36501509,0.912546559 C0.381679025,0.618295681 0.618746852,0.380026432 0.953968263,0.35296528 L4.87756656,0.353817514 C5.01468646,0.344576224 5.14906921,0.393548968 5.24590321,0.487832312 C5.34224403,0.581635464 5.39201596,0.711111001 5.38350131,0.818762557 L5.38350131,2.09872077 L44.8697066,2.09872077 Z"
              fillRule="nonzero"
            />
            <path
              d="M45.6136176,10.6366666 L45.5981002,21.5904526 L4.67634051,21.5904526 L4.67634051,10.6366666 L45.6136176,10.6366666 Z M44.8836797,11.3420904 L5.40527844,11.3420904 L5.40527844,20.8850288 L44.8701609,20.8850288 L44.8836797,11.3420904 Z"
              fillRule="nonzero"
            />
            <path
              d="M25.695062,7.75966184 C24.9905448,7.75966184 24.4194207,7.2069611 24.4194207,6.52517018 C24.4194207,5.84337926 24.9905448,5.29067852 25.695062,5.29067852 C26.3995793,5.29067852 26.9707034,5.84337926 26.9707034,6.52517018 C26.9707034,7.2069611 26.3995793,7.75966184 25.695062,7.75966184 Z M25.695062,7.05423803 C25.996998,7.05423803 26.2417655,6.81736629 26.2417655,6.52517018 C26.2417655,6.23297407 25.996998,5.99610233 25.695062,5.99610233 C25.3931261,5.99610233 25.1483586,6.23297407 25.1483586,6.52517018 C25.1483586,6.81736629 25.3931261,7.05423803 25.695062,7.05423803 Z"
              fillRule="nonzero"
            />
            <path
              d="M13.3031172,7.75966184 C12.5985999,7.75966184 12.0274758,7.2069611 12.0274758,6.52517018 C12.0274758,5.84337926 12.5985999,5.29067852 13.3031172,5.29067852 C14.0076345,5.29067852 14.5787586,5.84337926 14.5787586,6.52517018 C14.5787586,7.2069611 14.0076345,7.75966184 13.3031172,7.75966184 Z M13.3031172,7.05423803 C13.6050532,7.05423803 13.8498207,6.81736629 13.8498207,6.52517018 C13.8498207,6.23297407 13.6050532,5.99610233 13.3031172,5.99610233 C13.0011812,5.99610233 12.7564138,6.23297407 12.7564138,6.52517018 C12.7564138,6.81736629 13.0011812,7.05423803 13.3031172,7.05423803 Z"
              fillRule="nonzero"
            />
            <path
              d="M36.629131,7.75966184 C35.9246137,7.75966184 35.3534896,7.2069611 35.3534896,6.52517018 C35.3534896,5.84337926 35.9246137,5.29067852 36.629131,5.29067852 C37.3336483,5.29067852 37.9047724,5.84337926 37.9047724,6.52517018 C37.9047724,7.2069611 37.3336483,7.75966184 36.629131,7.75966184 Z M36.629131,7.05423803 C36.931067,7.05423803 37.1758344,6.81736629 37.1758344,6.52517018 C37.1758344,6.23297407 36.931067,5.99610233 36.629131,5.99610233 C36.327195,5.99610233 36.0824275,6.23297407 36.0824275,6.52517018 C36.0824275,6.81736629 36.327195,7.05423803 36.629131,7.05423803 Z"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

MachinesTab.defaultProps = {
  color: colors.black,
};

MachinesTab.propTypes = {
  color: PropTypes.string,
};

export default MachinesTab;
